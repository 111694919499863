import React, {useEffect, useRef, useState} from 'react';
import {BasePage} from "./BasePage";
import {Button, Heading, Radio, RadioGroup, useToken} from "@chakra-ui/react";
import {BorderBox} from "./BorderBox";
import {css} from "@emotion/react";
import {State, useHookstate} from "@hookstate/core";
import {SpecialistListItem} from "./InstitutionPage";
import {SearchInput} from "./SearchInput";
import {Institution, Position, User} from "../types";
import {getHighestProfessionType, getUserProfessionsFormatted} from "../heka-utils";
import {useTranslation} from "react-i18next";
import {
  createOutsiderPosition,
  createOutsiderUser,
  createPosition,
  getInstitutionById, getUser,
  searchUsersByNameOrSSN
} from "../net-utils";
import {DayShifts} from "./DayShifts";
import {
  ClinicInput,
  createBlankPosition, CreatePositionTemplateModal,
  PositionCheckboxes,
  PositionTemplateSelect,
  ProfessionTypeSelect, SaveTemplateButton, TimePeriodAndPaymentSelector, validatePosition
} from "./CreatePositionPage";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {DefaultSpinner} from "./DefaultSpinner";
import {HalfSection, SectionContainer} from "./SectionContainer";
import {InputForm} from "./InputForm";
import {validateEmail, validatePhone, validateSSN} from "../validations";
import {GoButton} from "./GoButton";
import AlertDisplay from "./AlertDisplay";
import {LabelInfo} from "./LabelInfo";

export interface ManualSpecialistInfo {
  ssn: string;
  email: string;
  phone: string;
}

function ManualSpecialistInput(props: {
  institutionId: string;
  onChange: (user: User) => void;
}) {
  const info = useHookstate({
    ssn: '',
    email: '',
    phone: ''
  } as ManualSpecialistInfo);
  const error = useHookstate(null as string | null);
  const invalid = validatePhone(info.phone.get()) || validateEmail(info.email.get()) || validateSSN(info.ssn.get());
  return <BorderBox css={css`display: flex; flex-direction: column; gap: 16px`}>
    <SectionContainer>
      <HalfSection>
        <InputForm label={'Kennitala'} type={'number'} validate={validateSSN} state={info.ssn}/>
        <InputForm label={'Netfang'} type={'email'} validate={validateEmail} state={info.email}/>
      </HalfSection>
      <HalfSection>
        <InputForm label={'Símanúmer'} type={'tel'} validate={validatePhone} state={info.phone}/>
      </HalfSection>
    </SectionContainer>
    <AlertDisplay error={error}/>
    <GoButton onClick={async () => {
      const user = await createOutsiderUser(props.institutionId, info.get());
      props.onChange(user);
    }} submitError={error} disabled={!!invalid}>
      Staðfesta
    </GoButton>
  </BorderBox>
}

function PositionInput(props: {
  institutionState: State<Institution>;
  position: State<Position>;
}) {
  const institutionState = useHookstate(props.institutionState);
  const institution = institutionState.get();
  const position = useHookstate(props.position);
  const clinic = institution.clinics.find(c => c.clinicId === position.clinicId.get());
  const templateUpdateCounter = useHookstate(0);
  const createPositionTemplateModalOpen = useHookstate(false);
  if (!clinic) {
    return <BorderBox>
      <Heading fontSize={'lg'}>Engin starfsstöð fyrir þessa stofnun fannst. Stofnaðu a.m.k. eina starfsstöð til þess að búa til samning.</Heading>
    </BorderBox>
  }
  return <BorderBox css={css`display: flex; flex-direction: column; gap: 8px;`}>
    <PositionTemplateSelect templateUpdateCounter={templateUpdateCounter} institution={institution} position={position}/>
    <ClinicInput position={position} institution={institution}/>
    <ProfessionTypeSelect position={position}/>
    <TimePeriodAndPaymentSelector position={position} institutionState={institutionState} clinic={clinic}/>
    <PositionCheckboxes position={position}/>
    <SaveTemplateButton templateModalOpen={createPositionTemplateModalOpen}/>
    <CreatePositionTemplateModal open={createPositionTemplateModalOpen}
      institutionId={institution.institutionId!} position={position.get()}
      update={() => templateUpdateCounter.set(templateUpdateCounter.get() + 1)}/>
  </BorderBox>
}

function SpecialistInfo(props: { user: User }) {
  const {t} = useTranslation();
  const specializations = props.user.preferences.professions.flatMap(p => p.specializations).join(', ');
  return <BorderBox css={css`display: flex; flex-direction: column; gap: 8px;`}>
    <Heading fontSize={'lg'}>Upplýsingar um sérfræðing</Heading>
    <SectionContainer>
      <HalfSection>
        <LabelInfo label={'Nafn'}>
          <a target={'_blank'} href={`/users/${props.user.username}`}>{props.user.electronicId.name}</a>
        </LabelInfo>
        <LabelInfo label={'Kennitala'}>
          {props.user.electronicId.ssn}
        </LabelInfo>
        <LabelInfo label={'Læknanúmer'}>
          {props.user.certificationId}
        </LabelInfo>
        <LabelInfo label={'Heilbrigðisstétt'}>
          {getUserProfessionsFormatted(props.user, t)}
        </LabelInfo>
      </HalfSection>
      <HalfSection>
        <LabelInfo label={'Netfang'}>
          <a target={'_blank'} href={`mailto:${props.user.preferences.email}`}>{props.user.preferences.email}</a>
        </LabelInfo>
        <LabelInfo label={'Símanúmer'}>
          {props.user.preferences.phoneNumbers[0].phoneNumber ?? 'Ekki skráð'}
        </LabelInfo>
        <LabelInfo label={'Sérgrein(ar)'}>
          {!!specializations.length ? specializations : 'Engar sérgreinar'}
        </LabelInfo>
      </HalfSection>
    </SectionContainer>
  </BorderBox>
}

function FindSpecialist(props: {
  onChange: (user: User | null) => void;
  value: User | null;
}) {
  const input = useHookstate('');
  const users = useHookstate([] as User[]);
  const firstRender = useRef(true);

  const fetchUsers = async (filter: string): Promise<User[]> => {
    return searchUsersByNameOrSSN(filter);
  }

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      fetchUsers(input.get()).then(u => users.set(u));
      return;
    }
    const timeout = setTimeout(async () => {
      users.set(await fetchUsers(input.get()));
    }, 1000);
    return () => clearTimeout(timeout);
  }, [input]);

  const [pink] = useToken('colors', ['pink.600']);
  const selectedCss = css`border-color: ${pink}; border-width: 2px; .chakra-heading { color: ${pink}; }`;
  const {t} = useTranslation();
  return <BorderBox css={css`display: flex; flex-direction: column; gap: 8px`}>
    <SearchInput input={input}/>
    <div css={css`display: flex; flex-direction: column; gap: 8px`}>
      {
        users.get().map(u => <SpecialistListItem name={u.electronicId.name}
          key={u.electronicId.ssn}
          specializations={getUserProfessionsFormatted(u, t)}
          img={u.preferences.links.picture}
          css={u.electronicId.ssn === props.value?.electronicId?.ssn ? selectedCss : undefined}
          username={u.username}
          onClick={() =>
            props.value?.electronicId.ssn === u.electronicId.ssn ? props.onChange(null) : props.onChange(u)
          }
        />)
      }
    </div>
  </BorderBox>;
}

type RegistrationType = 'heka' | 'manual';



const ContractOutsiderPage = () => {
  const [searchParams,] = useSearchParams();
  const clinicId = searchParams.get('clinic');
  const usernameParam = searchParams.get('username');
  const navigate = useNavigate();
  const params = useParams();
  const institutionId = params.institutionId as string | undefined;
  const [registrationType, setRegistrationType] = useState(null as RegistrationType | null);
  const [selectedUser, setSelectedUser] = useState(null as User | null);
  const institutionState = useHookstate(null as Institution | null);
  const positionState = useHookstate(null as Position | null);
  const institutionStateOrNull = institutionState.ornull;
  const positionStateOrNull = positionState.ornull;
  const error = useHookstate(null as string | null);


  const stepTwo: Record<RegistrationType, JSX.Element> = {
    'heka': <FindSpecialist value={selectedUser} onChange={user => {
      if (user) {
        const professionType = getHighestProfessionType(user);
        positionStateOrNull?.professionType.set(professionType);
      }
      setSelectedUser(user);
    }}/>,
    'manual': <ManualSpecialistInput institutionId={institutionId!} onChange={setSelectedUser}/>
  }

  useEffect(() => {
    if (!institutionId) {
      return;
    }
    (async () => {
      const institution = await getInstitutionById(institutionId);
      institutionState.set(institution);
      let validatedClinicId;
      if (clinicId) {
        validatedClinicId = institution.clinics.find(c => c.clinicId === clinicId) ? clinicId : undefined;
      }
      positionState.set(createBlankPosition(institution, validatedClinicId));
    })();

    (async () => {
      if (usernameParam) {
        const user = await getUser(usernameParam);
        setSelectedUser(user.user)
      }
    })();
  }, [institutionId]);


  if (!institutionId) {
    return <Heading fontSize={'2xl'}>Villa: Stofnun ekki valin</Heading>
  }

  if (!institutionStateOrNull || !positionStateOrNull) {
    return <DefaultSpinner/>
  }

  return (
    <BasePage>
      <Heading fontSize={'2xl'}>Búa til samning án þess að búa til auglýsingu</Heading>
      {!usernameParam && <BorderBox css={css`display: flex;
        flex-direction: column;
        gap: 8px;`}>
        <Heading fontSize={'lg'}>Verktakinn er</Heading>
        <RadioGroup css={css`display: flex;
          flex-direction: column;
          gap: 7px;`} onChange={
          (e) => setRegistrationType(e as RegistrationType)
        }>
          <Radio value={'heka'} size={'lg'}>með aðgang að Heka og ég mun velja hann í næsta skrefi</Radio>
          <Radio value={'manual'} size={'lg'}>ekki með aðgang að Heka og ég mun skrá inn upplýsingar hans</Radio>
        </RadioGroup>
      </BorderBox>}
      {registrationType && stepTwo[registrationType]}
      {selectedUser && <SpecialistInfo user={selectedUser}/>}
      {selectedUser && <PositionInput institutionState={institutionStateOrNull} position={positionStateOrNull}/>}
      {selectedUser && <GoButton onClick={async () => {
        const errors = validatePosition(positionStateOrNull!.get());
        if (errors.length) {
          error.set(errors.join('\n'));
          return;
        }
        const contractId = await createOutsiderPosition(institutionId, selectedUser.username, positionStateOrNull!.get());
        navigate(`/contracts/${contractId}`);
      }} submitError={error}>
        Staðfesta
      </GoButton>}
      <AlertDisplay error={error}/>
    </BasePage>
  );
};

export default ContractOutsiderPage;